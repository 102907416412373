import React, { useState, useEffect } from "react";
import { StaticImage } from "gatsby-plugin-image";
import { useBreakpoint } from "gatsby-plugin-breakpoints";
import { Link } from "react-scroll";
import { useWindowScroll, useDebounce } from "react-use";

import { scrollToSection } from "../shared/scrollHelper";

import "../styles/navbar.scss";

const scrollMinimizedLimit = 50;

const Navbar = () => {
  const breakpoints = useBreakpoint();
  const { y } = useWindowScroll();
  const [isMinimizedBar, setIsMinimizedBar] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [debouncedY, setDebouncedY] = useState(0);

  const menuOpenHandler = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useDebounce(
    () => {
      setDebouncedY(y);
    },
    200,
    [y]
  );

  useEffect(() => {
    if (debouncedY >= scrollMinimizedLimit && !isMinimizedBar) {
      setIsMinimizedBar(true);
    } else if (debouncedY < scrollMinimizedLimit && isMinimizedBar) {
      setIsMinimizedBar(false);
    }
  }, [debouncedY, isMinimizedBar]);

  useEffect(() => {
    console.log(breakpoints.md);
    if (breakpoints.md) {
      setIsMenuOpen(false);
    }
  }, [breakpoints.md]);

  return (
    <nav className="navbar">
      {breakpoints.md ? (
        <>
          <div
            className={`navbar__nav-bar navbar__nav-bar--mobile ${
              isMinimizedBar && "navbar__nav-bar--minimized"
            }`}
          >
            <button
              onClick={() => {
                if (isMenuOpen) {
                  setIsMenuOpen(false);
                }
                scrollToSection("header");
              }}
            >
              <StaticImage
                src="../images/logoPK.png"
                alt="Logo ecofill navegacion"
                placeholder="blurred"
                layout="fixed"
                width={60}
                height={60}
              />
            </button>
            <label>
              <input
                checked={isMenuOpen}
                onChange={menuOpenHandler}
                type="checkbox"
              />
              <span className="menu">
                <span className="hamburger"></span>
              </span>
              <ul className="menu-buttons">
                <li>
                  <button
                    onClick={() => {
                      if (isMenuOpen) {
                        setIsMenuOpen(false);
                      }
                      scrollToSection("about-us", -50);
                    }}
                  >
                    Nosotros
                  </button>
                </li>

                <li>
                  <button
                    onClick={() => {
                      if (isMenuOpen) {
                        setIsMenuOpen(false);
                      }
                      scrollToSection("contact", -80);
                    }}
                  >
                    Contacto
                  </button>
                </li>
              </ul>
            </label>
          </div>
        </>
      ) : (
        <div
          className={`navbar__nav-bar ${
            isMinimizedBar && "navbar__nav-bar--minimized"
          }`}
        >
          <Link
            activeClass="active"
            to="header"
            spy={true}
            smooth={true}
            offset={-50}
            duration={500}
          >
            <StaticImage
              className="navbar__nav-logo"
              src="../images/logoPK.png"
              alt="Logo ecofill navegacion"
              placeholder="blurred"
              layout="fixed"
              width={115}
              height={115}
            />
          </Link>

          <ul className="navbar__nav-links-wrapper">
            <li>
              <Link
                activeClass="active"
                to="about-us"
                spy={true}
                smooth={true}
                offset={-50}
                duration={500}
              >
                Nosotros
              </Link>
            </li>

            <li>
              <Link
                activeClass="active"
                to="contact"
                spy={true}
                smooth={true}
                offset={-50}
                duration={500}
              >
                Contacto
              </Link>
            </li>
          </ul>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
