import React, { useState } from "react";
import { Helmet } from "react-helmet";

import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { useBreakpoint } from "gatsby-plugin-breakpoints";
import {
  FaFacebook,
  FaInstagram,
  FaTiktok,
  FaWhatsapp,
  //FaEnvelope,
} from "react-icons/fa";

import Navbar from "../components/Navbar";
import ProductsModal from "../components/ProductsModal";
import { scrollToSection } from "../shared/scrollHelper";

// styles
import "../styles/reset.scss";
import "../styles/shared.scss";
import "../styles/landing.scss";

// markup
const IndexPage = ({ data }) => {
  const [showModal, setShowModal] = useState(false);
  const breakpoints = useBreakpoint();
  const listOfProducts = data?.allProductosCsv?.edges;

  const toggleModal = (show) => {
    setShowModal(show);
    if (show) {
      document.body.classList.add("scroll-lock");
    } else {
      document.body.classList.remove("scroll-lock");
    }
  };

  return (
    <main className="landing">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Power Kids and Teens</title>
        <meta
          name="description"
          content="Entrenamiento deportivo para niños en Ciudad Juárez."
        />
        <meta name="robots" content="index, follow" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0"
        ></meta>
      </Helmet>

      <Navbar />

      <header className="landing__header" id="header">
        {breakpoints.sm ? (
          <StaticImage
            className="landing__header-image"
            src="../images/headerBg_small.jpg"
            aspectRatio={9 / 16}
            alt=""
            placeholder="blurred"
            layout="fullWidth"
          />
        ) : (
          <StaticImage
            className="landing__header-image"
            src="../images/herobg.jpg"
            aspectRatio={16 / 9}
            alt=""
            placeholder="blurred"
            layout="fullWidth"
          />
        )}
        <div className="landing__header-text-wrapper">
          <div>
            <h1>POWER KIDS</h1>
            <h2>El entrenamiento físico ideal para los niños.</h2>
          </div>
        </div>
      </header>
      <section className="landing__about-us" id="about-us">
        <h1>
          Nosotros desarrollamos programas de entrenamiento fisico para niños,
          adicionalmente damos capacitaciones deportivas a entrenadores.
        </h1>
      </section>
      <section className="landing__features">
        <article>
          <h1>Entrenamiento fisico para niños</h1>
          <ul>
            <li>
              Programas divertidos de entrenamiento fisico para niños de 4 a 17
              años
            </li>
            <li>Impartidos de manera presencial o virtual</li>
            <li>Contribuye a llevar una vida más sana</li>
            <li>Ayuda a mejorar en los deportes</li>
            <li>Complementa a tratamientos médicos</li>
          </ul>

          {/*<br />
          <button
            className="button"
            onClick={() => {
              toggleModal(true);
            }}
          >
            Ingresa para ver entrenamientos
          </button> */}
        </article>
        <article>
          <h1>Capacitaciones</h1>
          <p>
            Capacitaciones deportivas para Entrenadores y personas interesadas
            en aprender más acerca del entrenamiento deportivo.
          </p>
          {/*<br />
          <button
            className="button"
            onClick={() => {
              toggleModal(true);
            }}
          >
            Ingresa para ver entrenamientos
          </button> */}
        </article>
        <article>
          <h1>Entrenamientos personalizados</h1>
          <p>Hacemos entrenamientos personalizados</p>
          {/*<br />
          <button
            className="button"
            onClick={() => {
              toggleModal(true);
            }}
          >
            Ingresa para ver entrenamientos
          </button> */}
        </article>
      </section>

      {/*
        <section className="landing__dynamics" id="dynamics">
        <div className="landing__social-proof">
          <div className="landing__social-proof-img">
            <StaticImage
              src="../images/carmen.jpeg"
              alt="Pesa, rellena, paga"
              placeholder="blurred"
              layout="constrained"
              width={100}
              height={100}
            />
          </div>
          <div>
            <h2>"¡Una experiencia única!</h2>
            <br />
            <p>
              Al comprar en Ecofill me atendieron de una forma estupenda, los
              productos son de alta calidad y muy buen precio. Lo mejor es que
              cuidan al medio ambiente."
            </p>
            <br />
            <div className="landing__social-proof-author">- Carmen Z.</div>
          </div>
        </div>
        <StaticImage
          className="landing__dynamics-steps"
          src="../images/dinamica.svg"
          alt="Pesa, rellena, paga"
          placeholder="blurred"
          layout="fullWidth"
        />
        <div className="landing__dynamics-figures">
          <figure>
            <StaticImage
              className="landing__dynamics-image"
              src="../images/botes.jpg"
              alt="Frascos de vidrio con productos a granel"
              placeholder="blurred"
              layout="fullWidth"
            />
            <figcaption>
              <h2>Reutiliza tus recipientes</h2>
              <p>¿Tienes algun recipiente de este tipo?</p>
              <br />
              <ul>
                <li>Frasco de vidrio</li>
                <li>Tupper</li>
                <li>Envase plástico</li>
                <li>Bote</li>
                <li>Etc...</li>
              </ul>
              <br />
              <p>
                ¡Utilízalo para hacer tu despensa en nuestro local y obtén un 5%
                de descuento!
              </p>
            </figcaption>
          </figure>
          <figure>
            <StaticImage
              className="landing__dynamics-image"
              src="../images/insumos.jpeg"
              alt="Local de Ecofill en plaza de las americas"
              placeholder="blurred"
              layout="fullWidth"
            />
            <figcaption>
              <h2>Descubre nuestros productos</h2>
              <p>Algunos productos de despensa básica que tenemos:</p>
              <br />
              <ul>
                <li>Avena</li>
                <li>Lenteja</li>
                <li>Soya texturizada</li>
              </ul>
              <br />
              <p>También contamos con productos gourmet:</p>
              <br />
              <ul>
                <li>Pimienta gorda</li>
                <li>Gengibre en polvo</li>
                <li>Salsa macha "Mashipotle"</li>
                <li>Espirulina</li>
                <li>Acai</li>
              </ul>
              <br />
              <p>Y muchos más...</p>
            </figcaption>
          </figure>
        </div>

        <div className="landing__social-proof landing__social-proof--bottom">
          <div className="landing__social-proof-img">
            <StaticImage
              src="../images/lulu.jpg"
              alt="Pesa, rellena, paga"
              placeholder="blurred"
              layout="constrained"
              width={100}
              height={100}
            />
          </div>
          <div>
            <h2>"¡Me encanta Ecofill!</h2>
            <br />
            <p>
              Es la mejor tienda a granel en Ciudad Juárez, tienen un excelente
              servicio al cliente, son muy amables al atenderte y me hace feliz
              poder ayudar un poquito al planeta al comprar aquí."
            </p>
            <br />
            <div className="landing__social-proof-author">- Lourdes R.</div>
          </div>
        </div>
      </section>

      <section className="landing__location" id="location">
        <h1>¡Visítanos en nuestro local!</h1>
        <p>Plaza de las Américas local D-11, cerca de la pista de hielo.</p>
        <h2>Horarios</h2>
        <p>
          Lunes a Sábado de 10AM - 7PM <br />
          Domingo de 11AM - 4PM
        </p>
        {breakpoints.md ? (
          <StaticImage
            className="landing__location-map"
            src="../images/americasLocalMobile.jpg"
            alt="Mapa de plaza de las Americas"
            placeholder="blurred"
            layout="fullWidth"
          />
        ) : (
          <StaticImage
            className="landing__location-map"
            src="../images/americasLocal.jpg"
            alt="Mapa de plaza de las Americas"
            placeholder="blurred"
            layout="fullWidth"
          />
        )}
      </section> */}

      <section className="landing__contact-us" id="contact">
        {/*
        <h1>¡Contáctanos!</h1>
        <p>Estaremos felices de comunicarnos contigo</p>
        <div>
          <StaticImage
            className="landing__contact-us-image"
            src="../images/contactUs.svg"
            alt="Contactanos"
            placeholder="blurred"
            layout="constrained"
          />
        </div>
        <div>
          <a href="whatsapp://send?text=Hola&phone=+526531702114&abid=+526531702114">
            <button className="button button--alternate">
              Envíanos un Whatsapp
              <span className="icon">
                <FaWhatsapp />
              </span>
            </button>
          </a>
        </div>
         */}

        <h1>Redes sociales</h1>

        <div>
          <a
            href="https://www.facebook.com/powerkidscj"
            target="_blank"
            rel="noreferrer"
          >
            <button className="button button--alternate">
              <span className="icon">
                <FaFacebook />
              </span>
            </button>
          </a>
          <a
            href="https://www.instagram.com/powerkidsandteens/"
            target="_blank"
            rel="noreferrer"
          >
            <button className="button button--alternate">
              <span className="icon">
                <FaInstagram />
              </span>
            </button>
          </a>
        </div>
      </section>
      <footer>
        <section>
          Power kids and teens 2022 <br />
          <br /> contacto@powerkidsandteens.com
        </section>
      </footer>
    </main>
  );
};

export const query = graphql`
  {
    allProductosCsv {
      edges {
        node {
          name
          category
        }
      }
    }
  }
`;

export default IndexPage;
