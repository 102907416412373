import { animateScroll as scroll } from "react-scroll";

export const scrollToSection = (sectionId, offset) => {
  console.log("offset", offset);
  const posY = Math.floor(
    document.getElementById(sectionId).offsetTop + (offset || 0)
  );

  scroll.scrollTo(posY, {
    activeClass: "active",
    smooth: true,
    duration: 1500,
  });
};
